import { SignUpButton } from '@clerk/nextjs';
import { Button } from '@nextui-org/button';
import { Card, CardBody, CardHeader } from '@nextui-org/card';
import { Divider } from '@nextui-org/divider';
import Head from 'next/head';

export default function Welcome() {
  return (
    <>
      <Head>
        <title>Qualia Invoicing Solutions</title>
        <meta name="description" content="Discover our invoicing solutions for your business." />
      </Head>
      <div className="flex flex-col bg-background text-foreground dark:bg-dark-primary dark:text-dark-primary-foreground">
        {/* Main Section */}
        <main className="container mx-auto px-4 py-8 flex-grow flex flex-col items-center">
          <section className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">Effortless Invoicing</h2>
            <p className="text-lg mb-6">Easily create, send, and manage invoices to keep your business running smoothly.</p>

            <SignUpButton>
              <Button color="secondary" className="px-8 py-3 rounded-lg shadow-lg hover:bg-warning">
                Get Started
              </Button>
            </SignUpButton>
          </section>

          {/* Features Section */}
          <section className="w-full max-w-6xl">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <Card className="bg-primary bg-opacity-60 text-primary-foreground p-4 rounded-lg shadow-lg">
                <CardHeader>
                  <p className="text-md">Create Invoices in Seconds</p>
                </CardHeader>
                <Divider />
                <CardBody>
                  <p>Generate professional invoices with just a few clicks.</p>
                </CardBody>
              </Card>

              <Card className="bg-primary bg-opacity-80 text-primary-foreground p-4 rounded-lg shadow-lg">
                <CardHeader>
                  <p className="text-md">Track Payments</p>
                </CardHeader>
                <Divider />
                <CardBody>
                  <p>Easily keep track of paid and unpaid invoices in real-time.</p>
                </CardBody>
              </Card>

              <Card className="bg-primary text-primary-foreground p-4 rounded-lg shadow-lg">
                <CardHeader>
                  <p className="text-md">Automate Reminders</p>
                </CardHeader>
                <Divider />
                <CardBody>
                  <p>Send automatic payment reminders to ensure timely payments.</p>
                </CardBody>
              </Card>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
