import { OrganizationList, SignOutButton } from '@clerk/nextjs';
import { Icon } from '@iconify/react';
import { Button, Spacer } from '@nextui-org/react';
import Link from 'next/link';
import React from 'react';

const OrganizationSelector: React.FC = () => {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Choose your organization
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <OrganizationList
            hidePersonal={true}
            afterSelectPersonalUrl="/"
            afterCreateOrganizationUrl="/create-organization"
            afterSelectOrganizationUrl="/:slug/dossiers"
            skipInvitationScreen={true}
          />
        </div>
      </div>
      <Spacer y={4} />
      <SignOutButton>
        <Button as={Link} color="primary" href="#" variant="flat"
          startContent={<Icon
            width={24}
            icon="ri:logout-box-line"
          />}>
          Sign Out
        </Button>
      </SignOutButton>
    </div>
  );
};

export default OrganizationSelector;